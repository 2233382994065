import React from "react"
import { Helmet } from "react-helmet"
import GatsbyConfig from "../../gatsby-config"

// spolecne komponenty
import Header from "../components/blocks/Header/Header"
import Footer from "../components/blocks/Footer/Footer"
import HowDoesItWork from "../components/blocks/HowDoesItWork/HowDoesItWork"

// styly komponenty
import styles from "../components/pages/Homepage/homepage.module.scss"

// bloky homepage
import Block01 from "../components/pages/Homepage/Block01/Block01"
import Block03 from "../components/pages/Homepage/Block03/Block03"
//import Block04 from "../components/pages/Homepage/Block04/Block04"

// seo image
import SeoImage from "../components/pages/Homepage/static/seo-image.jpg"

export default function HomePage(props) {
  // nastaveni meta pro stranku
  const meta = {
    title: "Home | " + GatsbyConfig.siteMetadata.siteTitle,
    description:
      "Projekt Sev.en Energy for Bikers je síť nabíjecích stanic Powerbox.One pro elektrokola. Pro nabíjení není potřeba sebou vozit nabíječku. Stačí si vypůjčit kabel od obsluhy. Nabíjecí stanice poskytují širokou kompatibilitou až pro 90% elektrokol prodávaných v České republice.",
    image: SeoImage,
  }

  return (
    <div className={styles.container}>
      {/* nastaveni meta hlavicky */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={GatsbyConfig.siteMetadata.twitterUsername}
        />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>

      {/* hlavicka s navigaci */}
      <Header />

      {/* Blok 01 */}
      <Block01 />

      {/* Blok 02 - jak to funguje */}
      <HowDoesItWork backgroundColor="#ffffff" />

      {/* Blok 03 */}
      <Block03 />

      {/* Blok 04
        <Block04 />
      */}
      {/* Paticka */}
      <Footer />
    </div>
  )
}
